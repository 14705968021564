<template>
    <form @submit.prevent="handleEdit"
          v-if="product"
          class="flex-1 overflow-y-auto h-full">
        <PageHead sticky
                  :title="`Produkt - ${product.name_de}`">
            <template #actions>
                <c-button class="mr-2"
                          v-if="enclosing.prev"
                          :path="{ name: 'ProductEdit', params: { id: enclosing.prev.id } }">&lt;
                </c-button>
                <c-button class="mr-2"
                          disabled
                          v-else>&lt;
                </c-button>
                <c-button class="mr-2"
                          v-if="enclosing.next"
                          :path="{ name: 'ProductEdit', params: { id: enclosing.next.id } }">&gt;
                </c-button>
                <c-button class="mr-2"
                          disabled
                          v-else>&gt;
                </c-button>

                <c-button :disabled="noChange">
                    Speichern
                    <component :is="saveIcon"
                               class="h-5 w-5 rounded-full text-white ml-2" />
                </c-button>
            </template>
        </PageHead>

        <nav class="relative z-0 flex divide-x divide-gray-200"
             aria-label="Tabs">
            <a v-for="(tab, tabIdx) in tabs"
               @click.prevent="currentTab = tabIdx"
               :key="tab.name"
               href="#"
               :class="[
                   currentTab === tabIdx ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                   'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10',
               ]">
                <span>{{ tab.name }}</span>
                <span aria-hidden="true"
                      :class="[currentTab === tabIdx ? 'bg-gray-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
            </a>
        </nav>
        <div class="p-6 bg-white">
            <div v-if="currentTab === 0">
                <div class="grid grid-cols-4 gap-6">

                    <!-- Product number -->
                    <div class="col-span-2">
                        <label for="number"
                               class="block text-sm font-medium text-gray-700">
                            Produktnummer
                        </label>
                        <input type="text"
                               v-model="form.number"
                               name="number"
                               id="number"
                               class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />
                    </div>

                    <!-- Name -->
                    <div class="col-span-2">
                        <label for="name_de"
                               class="block text-sm font-medium text-gray-700">
                            Name
                        </label>
                        <input type="text"
                               v-model="form.name_de"
                               name="name_de"
                               id="name_de"
                               class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />
                    </div>

                    <!-- Description -->
                    <div class="col-span-4">
                        <label for="description_de"
                               class="block text-sm font-medium text-gray-700">
                            Beschreibung
                        </label>
                        <div class="mt-1">
                            <textarea id="description_de"
                                      v-model="form.desc_de"
                                      name="description_de"
                                      rows="4"
                                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />
                        </div>
                    </div>

                    <!-- Price -->
                    <div class="col-span-2">
                        <label for="price"
                               class="block text-sm font-medium text-gray-700">
                            Preis
                        </label>
                        <input type="number"
                               step="0.01"
                               v-model="form.price"
                               name="price"
                               id="price"
                               class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />
                    </div>

                    <!-- Quantity -->
                    <div class="col-span-2">
                        <label for="quantity"
                               class="block text-sm font-medium text-gray-700">
                            Menge
                        </label>
                        <input type="text"
                               step="0.01"
                               v-model="form.quantity"
                               name="quantity"
                               id="quantity"
                               class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />
                    </div>
                    <div class="col-span-4 md:col-span-2">
                        <label for="category"
                               class="block text-sm font-medium text-gray-700">Kategorie</label>
                        <select id="category"
                                name="category"
                                v-model="form.category"
                                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm">
                            <option value="undefined"
                                    disabled
                                    selected>Kategorie wählen
                            </option>
                            <option v-for="(category, categoryIdx) in categories"
                                    :key="categoryIdx"
                                    :value="category.id">
                                {{ category.name_de }}
                            </option>
                        </select>
                    </div>
                    <div class="col-span-4 md:col-span-2 flex items-center">
                        <div class="grid grid-cols-2 w-full">
                            <div class="col-span-1">
                                <SwitchGroup as="div"
                                             class="flex items-center justify-between">
                                    <Switch v-model="form.isVegan"
                                            :class="[form.isVegan ? 'bg-mauros-green-600' : 'bg-gray-200', 'mr-3 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mauros-green-500']">
                                    <span aria-hidden="true"
                                          :class="[form.isVegan ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                                    </Switch>
                                    <span class="flex-grow flex flex-col">
                                  <SwitchLabel as="span"
                                               class="text-sm font-medium text-gray-900"
                                               passive>Vegan</SwitchLabel>
                                  <SwitchDescription as="span"
                                                     class="text-sm text-gray-500">Produkt ist Vegan</SwitchDescription>
                                </span>
                                </SwitchGroup>
                            </div>

                            <div class="col-span-1">
                                <SwitchGroup as="div"
                                             class="ml-4 flex items-center justify-between">
                                    <Switch v-model="form.isNew"
                                            :class="[form.isNew ? 'bg-mauros-green-600' : 'bg-gray-200', 'mr-3 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mauros-green-500']">
                                    <span aria-hidden="true"
                                          :class="[form.isNew ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                                    </Switch>
                                    <span class="flex-grow flex flex-col">
                                  <SwitchLabel as="span"
                                               class="text-sm font-medium text-gray-900"
                                               passive>Neu</SwitchLabel>
                                  <SwitchDescription as="span"
                                                     class="text-sm text-gray-500">Produkt ist neu</SwitchDescription>
                                </span>
                                </SwitchGroup>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-4">
                        <div class="grid grid-cols-4 gap-6">

                            <div class="col-span-4 md:col-span-2">
                                <fieldset>
                                    <legend class="text-lg font-medium text-gray-900">Inhaltspflichtige Stoffe</legend>
                                    <div class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                                        <div v-for="(ingredient, ingredientIdx) in ingredientsSubjectToLabeling"
                                             :key="ingredientIdx"
                                             class="relative flex items-start py-4">
                                            <div class="min-w-0 flex-1 text-sm">
                                                <label :for="`ingredient-${ingredient.id}`"
                                                       class="font-medium text-gray-700 select-none">
                                                    {{ ingredient.label }}
                                                    {{ ingredient.name_de }}</label>
                                            </div>
                                            <div class="ml-3 flex items-center h-5">
                                                <input v-model="form.ingredientsSubjectToLabeling"
                                                       :value="ingredient.id"
                                                       :id="`ingredient-${ingredient.id}`"
                                                       :name="`ingredient-${ingredient.id}`"
                                                       type="checkbox"
                                                       class="focus:ring-mauros-green-500 h-4 w-4 text-mauros-green-600 border-gray-300 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="col-span-4 md:col-span-2">
                                <fieldset>
                                    <legend class="text-lg font-medium text-gray-900">Allergene</legend>
                                    <div class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                                        <div v-for="(allergen, allergenIdx) in allergens"
                                             :key="allergenIdx"
                                             class="relative flex items-start py-4">
                                            <div class="min-w-0 flex-1 text-sm">
                                                <label :for="`allergen-${allergen.id}`"
                                                       class="font-medium text-gray-700 select-none">{{
                                                        allergen.name_de
                                                                                                     }}</label>
                                            </div>
                                            <div class="ml-3 flex items-center h-5">
                                                <input v-model="form.allergens"
                                                       :value="allergen.id"
                                                       :id="`allergen-${allergen.id}`"
                                                       :name="`allergen-${allergen.id}`"
                                                       type="checkbox"
                                                       class="focus:ring-mauros-green-500 h-4 w-4 text-mauros-green-600 border-gray-300 rounded" />
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div v-if="currentTab === 1">
                <div class="grid grid-cols-4 gap-6">
                    <div class="col-span-4">
                        <label for="name_en"
                               class="block text-sm font-medium text-gray-700">
                            Name (EN)
                        </label>
                        <input type="text"
                               v-model="form.name_en"
                               name="name_en"
                               id="name_en"
                               autocomplete="cc-given-name"
                               class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />
                    </div>
                    <div class="col-span-4">
                        <label for="description_en"
                               class="block text-sm font-medium text-gray-700">
                            Beschreibung (EN)
                        </label>
                        <div class="mt-1">
                            <textarea id="description_en"
                                      v-model="form.desc_en"
                                      name="description_en"
                                      rows="4"
                                      class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import {SaveIcon} from '@heroicons/vue/outline';
import {diff} from 'deep-object-diff';
import {Switch, SwitchGroup, SwitchLabel, SwitchDescription} from '@headlessui/vue';
import CButton from '@/components/CButton';

export default {
    name: 'ProductEdit',
    components: {
        CButton,
        Switch,
        SwitchGroup,
        SwitchLabel,
        SwitchDescription,
    },
    data() {
        return {
            currentTab: 0,
            form: {
                number: '',
                name_de: '',
                name_en: '',
                desc_de: '',
                desc_en: '',
                price: 0,
                ingredientsSubjectToLabeling: [],
                allergens: [],
                isVegan: false,
                size: false,
                isNew: false,
                quantity: '',
            },
            formOg: {},
            tabs: [
                {
                    name: 'Deutsch',
                    href: '#',
                },
                {
                    name: 'English',
                    href: '#',
                },
            ],
        };
    },
    beforeMount() {
        this.update();
    },
    watch: {
        $route() {
            this.update();
        },
        product(newProduct, oldProduct) {
            if (!oldProduct) {
                this.form = JSON.parse(JSON.stringify(newProduct));
            }
            if (newProduct) {
                this.formOg = JSON.parse(JSON.stringify(newProduct));
            }
        },
    },
    methods: {
        ...mapActions('products', ['editProduct']),
        update() {
            if (this.product) {
                this.form = JSON.parse(JSON.stringify(this.product));
                this.formOg = JSON.parse(JSON.stringify(this.product));
            }
        },
        async handleEdit() {
            const edit = await this.editProduct({
                id: this.productId,
                product: this.form,
            });
        },
    },
    computed: {
        ...mapGetters('products', ['getIngredientsSubjectToLabeling', 'getAllergens', 'getProductById', 'getCategories', 'getEnclosingProducts']),
        productId() {
            return this.$route.params?.id;
        },
        product() {
            return this.getProductById(this.productId);
        },
        ingredientsSubjectToLabeling() {
            return this.getIngredientsSubjectToLabeling();
        },
        allergens() {
            return this.getAllergens();
        },
        categories() {
            return this.getCategories();
        },
        enclosing() {
            return this.getEnclosingProducts(this.productId);
        },
        noChange() {
            return Object.keys(diff(this.formOg, this.form)).length <= 0;
        },
        saveIcon() {
            return SaveIcon;
        },
    },
};
</script>
